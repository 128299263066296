import React, {
    ChangeEvent,
    FocusEvent,
    ForwardedRef,
    forwardRef,
    InputHTMLAttributes,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useDebounce, useOnClickOutside } from '@dostavkee/react-hooks'

import { IconClearFilled } from '../icon/icon-clear-filled'
import { IconSearch } from '../icon/icon-search'
import { Input, InputProps } from '../input'
import { Spinner } from '../spinner'
import styles from './autocomplete.module.scss'

export interface AutocompleteOption<T extends {}> {
    id: string | number
    name: string
    payload?: T
    hint?: string
}

export interface AutocompleteProps<T extends {}>
    extends Omit<InputProps, 'onSelect' | 'value'>,
        Omit<InputHTMLAttributes<HTMLInputElement>, 'onSelect' | 'value'> {
    loadOptions: (inputValue: string) => Promise<AutocompleteOption<T>[]>
    onSelect: (option?: AutocompleteOption<T>) => void
    value: AutocompleteOption<T>['name']
    footer?: (isOpen: boolean, setIsOpen: (isOpen: boolean) => void) => ReactNode
    noResult?: ReactNode | null
}

export function AutocompleteInner<T extends {}>(
    {
        loadOptions,
        onSelect,
        errorMessage,
        isInvalid,
        value,
        onFocus,
        footer,
        noResult = null,
        ...props
    }: AutocompleteProps<T>,
    ref: ForwardedRef<HTMLInputElement>
) {
    const [search, setSearch] = useState<string>('')
    const debouncedSearch = useDebounce<string>(search, 500)
    const [options, setOptions] = useState<AutocompleteOption<T>[]>([])
    const [isOpen, setIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const listRef = useRef<HTMLDivElement>(null)

    const updateSelectOptions = useCallback(
        async (value: string) => {
            try {
                setIsLoading(true)
                const options = await loadOptions(value)
                setOptions(options ?? [])
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoading(false)
            }
        },
        [loadOptions]
    )

    const handleSelectOption = useCallback(
        (option: AutocompleteOption<T>) => {
            setSearch('')
            onSelect(option)
            setIsOpen(false)
        },
        [onSelect]
    )

    const handleChangeSearch = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setSearch(event.currentTarget.value)

            /**
             * Reset selected option
             */
            onSelect()
        },
        [onSelect]
    )

    const handleFocusSearch = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            if (typeof onFocus === 'function') {
                onFocus(event)
            }

            setIsOpen(true)
        },
        [onFocus]
    )

    useEffect(() => {
        if (debouncedSearch) {
            updateSelectOptions(debouncedSearch)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch])

    useOnClickOutside(listRef, () => {
        setOptions([])
    })

    return (
        <div className={styles['suggestions-wrapper']}>
            <Input
                ref={ref}
                autoComplete='off'
                postfixEl={
                    isLoading ? (
                        <Spinner size='small' />
                    ) : value ? (
                        <div
                            role='button'
                            style={{ cursor: 'pointer', height: 22 }}
                            onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                onSelect()
                                setSearch('')
                                setOptions([])
                            }}
                        >
                            <IconClearFilled color='var(--text-and-icon-secondary)' size={22} />
                        </div>
                    ) : (
                        <IconSearch color='var(--text-and-icon-secondary)' size={22} />
                    )
                }
                {...props}
                errorMessage={errorMessage}
                isInvalid={isInvalid}
                label={props.label}
                value={value || search}
                onChange={handleChangeSearch}
                onFocus={handleFocusSearch}
            />
            {!isLoading && isOpen && debouncedSearch && (
                <div ref={listRef} className={styles['suggestion-list']}>
                    {options?.length
                        ? options!.map((option) => (
                              <div
                                  key={option.id}
                                  className={styles['suggestion-list__item']}
                                  role='button'
                                  onClick={() => handleSelectOption(option)}
                              >
                                  {option.name}
                                  {option.hint && (
                                      <div className={styles['suggestion-list__item-hint']}>
                                          {option.hint}
                                      </div>
                                  )}
                              </div>
                          ))
                        : noResult}
                    {typeof footer === 'function' && footer(isOpen, setIsOpen)}
                </div>
            )}
        </div>
    )
}

export const Autocomplete = forwardRef(AutocompleteInner) as <T extends {}>(
    props: AutocompleteProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> }
) => ReturnType<typeof AutocompleteInner>
