import React, { memo } from 'react'
import { DomainCourierType } from '@dostavkee/contracts-console/entities'
import { useGetGeoCouriers } from '@dostavkee/contracts-console/hooks'
import Leaflet from 'leaflet'
import { Marker } from 'react-leaflet'

import svgIconCourierWalk from '@/assets/map-bike-courier-color-dark.svg'
import svgIconCourierAuto from '@/assets/map-car-color-dark.svg'

interface OrderFormMapCouriersProps {
    addressId: string
    lat: number
    lng: number
}

const COURIER_ICON: Record<DomainCourierType, string> = {
    walking: svgIconCourierWalk,
    auto: svgIconCourierAuto,
}

export const OrderFormMapCouriers = memo(({ addressId, lat, lng }: OrderFormMapCouriersProps) => {
    const isEnabled = Boolean(addressId && lat && lng)

    const { data: responseCouriers } = useGetGeoCouriers(
        {
            address_id: addressId as string,
            lat: lat?.toString() as string,
            lng: lng?.toString() as string,
        },
        {
            query: {
                enabled: isEnabled,
                refetchInterval: 4000,
            },
        }
    )

    if (!responseCouriers?.couriers) {
        return null
    }

    return responseCouriers.couriers.map((courier, index) => {
        if (!courier.lat || !courier.lng || !courier.type) {
            return null
        }

        return (
            <Marker
                key={index}
                position={[courier.lat, courier.lng]}
                icon={
                    new Leaflet.Icon({
                        iconUrl: COURIER_ICON[courier.type],
                        iconRetinaUrl: COURIER_ICON[courier.type],
                        iconSize: new Leaflet.Point(30, 30),
                    })
                }
            />
        )
    })
})

OrderFormMapCouriers.displayName = 'OrderFormMapCouriers'
