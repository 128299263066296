import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconArrowLeft: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.41392 11L11.707 5.70688C12.0975 5.31638 12.0975 4.68338 11.707 4.29288C11.3165 3.90237 10.6835 3.90237 10.293 4.29288L3.29301 11.2929C3.10651 11.4789 3.00001 11.7364 3.00001 11.9999C3.00001 12.2634 3.10651 12.5209 3.29301 12.7069L10.293 19.707C10.6835 20.0975 11.3165 20.0975 11.707 19.707C12.0975 19.3165 12.0975 18.6834 11.707 18.2929L6.41409 13H20.1C20.5973 13 21 12.5525 21 12C21 11.4475 20.5973 11 20.1 11H6.41392Z'
                fill={color}
            />
        </svg>
    )
}
