import React, { FC, memo, useEffect, useMemo } from 'react'
import { usePostGeoPrices } from '@dostavkee/contracts-console/hooks'
import { formatPlural, moneyFormatter } from '@dostavkee/helpers'
import { usePrevious } from '@dostavkee/react-hooks'
import {
    Alert,
    IconBolt,
    IconCoins,
    IconInfoFilled,
    InputMessage,
    InputMoney,
} from '@dostavkee/web-ui'
import * as Sentry from '@sentry/react'
import isEqual from 'lodash.isequal'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { Tooltip } from 'react-tooltip'

import { useBalanceFetch } from '@/routes/_dashboard/-hooks/use-balance-fetch'
import { useAuth } from '@/shared/hooks'

import { RecommendedPriceMode, useRecommendedPrice } from './hooks/use-recommended-price'
import { OrderFormValues } from './order-form'
import styles from './order-form-price.module.scss'
import { OrderFormTitle } from './order-form-title'

interface OrderFormPriceProps {}

const suffixes = {
    one: '%value% заказ',
    two: '%value% заказа',
    few: '%value% заказа',
    many: '%value% заказов',
}

const RecommendedPrice = () => {
    const { mode, priceText, throughPriceText } = useRecommendedPrice()
    const { profile } = useAuth()

    const bonus = profile?.user?.company?.bonus

    const hasBonusCount = typeof bonus?.count === 'number' && bonus.count > 0

    if (
        hasBonusCount &&
        (mode === RecommendedPriceMode.DYNAMIC_PRICE_WITH_BONUS ||
            mode === RecommendedPriceMode.STATIC_PRICE_WITH_BONUS)
    ) {
        return (
            <Alert
                data-tooltip-content={`На ${formatPlural(bonus.count!, suffixes)} скидка ${bonus.amount} ₽`}
                data-tooltip-id='bonus-price'
                data-tooltip-place='right'
                icon={<IconInfoFilled size={20} />}
                size='small'
                style={{ marginTop: 8 }}
                variant='success'
            >
                Рекомендованная цена:{' '}
                <div className={styles['recprice-bonus']}>
                    <span className={styles['recprice-through']}>{throughPriceText}</span>{' '}
                    <div className={styles['recprice-bonus__new-price']}>
                        <strong style={{ whiteSpace: 'nowrap' }}>{priceText}</strong> по акции
                    </div>
                </div>
                <Tooltip
                    className={styles['tooltip-high-rate']}
                    delayHide={0}
                    delayShow={0}
                    id='bonus-price'
                    place='top-start'
                />
            </Alert>
        )
    }

    if (mode === RecommendedPriceMode.DYNAMIC_PRICE || mode === RecommendedPriceMode.STATIC_PRICE) {
        return (
            <Alert
                icon={<IconInfoFilled size={20} />}
                size='small'
                style={{ marginTop: 8 }}
                variant='success'
            >
                Рекомендованная цена: <strong style={{ whiteSpace: 'nowrap' }}>{priceText}</strong>
            </Alert>
        )
    }

    return null
}

export const OrderFormPrice: FC<OrderFormPriceProps> = memo(() => {
    const { control, setValue } = useFormContext<OrderFormValues>()
    const { balance } = useBalanceFetch()

    const { profile } = useAuth()

    const { minPrice, isHighRate, setRecommendedPrice } = useRecommendedPrice()

    const points = useWatch({ name: 'points', control })
    const options = useWatch({ name: 'options', control })
    const needCar = Boolean(control._formValues?.options?.need_car)

    const preparedLocations = useMemo(
        () =>
            points
                ?.filter((point) => Boolean(point?.location))
                ?.filter((point) => point.location?.lat !== 0 && point.location?.lng !== 0)
                ?.map((point) => point.location) ?? [],
        [points]
    )

    const previousPreparedLocations = usePrevious(preparedLocations)
    const previousNeedCar = usePrevious(needCar)

    const { mutate: mutateRecommendedPrice } = usePostGeoPrices()

    useEffect(() => {
        const hasLocations = preparedLocations && preparedLocations.length >= 2
        const hasProfile = profile

        const isChangedPoints =
            hasLocations && hasProfile && !isEqual(preparedLocations, previousPreparedLocations)
        const isChangedOptions = hasLocations && hasProfile && !isEqual(needCar, previousNeedCar)

        if (isChangedPoints || isChangedOptions) {
            mutateRecommendedPrice(
                {
                    data: {
                        city_id: profile?.user?.city?.id,
                        company_id: profile?.user?.company?.id!,
                        options: {
                            car: needCar ?? false,
                            max_weight: options?.max_weight ?? 1,
                            thermal_bag: options?.need_thermal_bag ?? false,
                        },
                        points: preparedLocations,
                    },
                },
                {
                    onSuccess: (response) => {
                        setRecommendedPrice(response)
                        setValue('info', { routes: response.geometry })
                    },
                    onError: (error) => {
                        Sentry.captureException(error)
                    },
                }
            )
        }
    }, [
        preparedLocations,
        previousPreparedLocations,
        options,
        profile,
        needCar,
        previousNeedCar,
        setRecommendedPrice,
        setValue,
        mutateRecommendedPrice,
    ])

    return (
        <div>
            <OrderFormTitle>Цена за доставку</OrderFormTitle>
            <div className={styles['price-wrapper']}>
                <Controller
                    control={control}
                    name='price'
                    render={({ field, fieldState }) => (
                        <InputMoney
                            autoComplete='off'
                            id='price'
                            isInvalid={fieldState.invalid}
                            label='Предложите цену'
                            hint={
                                <div>
                                    {fieldState.invalid && fieldState.error?.message && (
                                        <InputMessage variant='error'>
                                            {fieldState.error?.message}
                                        </InputMessage>
                                    )}
                                    <RecommendedPrice />
                                </div>
                            }
                            postfixEl={
                                isHighRate && (
                                    <>
                                        <div
                                            data-tooltip-content='Сейчас повышенный спрос'
                                            data-tooltip-id='high-rate'
                                            data-tooltip-place='top-start'
                                        >
                                            <IconBolt color='var(--background-orange)' />
                                        </div>
                                    </>
                                )
                            }
                            {...field}
                        />
                    )}
                    rules={{
                        required: 'Обязательное поле',
                        min: {
                            message: `Минимальная сумма заказа ${minPrice} ₽`,
                            value: minPrice ?? 0,
                        },
                    }}
                />
                <Tooltip
                    className={styles['tooltip-high-rate']}
                    delayHide={0}
                    delayShow={0}
                    id='high-rate'
                    place='top-start'
                />
                {typeof balance?.amount === 'number' && (
                    <div className={styles['my-balance']}>
                        <IconCoins /> Ваш баланс: {moneyFormatter(balance.amount)}
                    </div>
                )}
            </div>
        </div>
    )
})

OrderFormPrice.displayName = 'OrderFormPrice'
