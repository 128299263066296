import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    PostGeoPrices400,
    PostGeoPricesMutationRequest,
    PostGeoPricesMutationResponse,
} from '../entities/PostGeoPrices'

type PostGeoPricesClient = typeof client<
    PostGeoPricesMutationResponse,
    PostGeoPrices400,
    PostGeoPricesMutationRequest
>
type PostGeoPrices = {
    data: PostGeoPricesMutationResponse
    error: PostGeoPrices400
    request: PostGeoPricesMutationRequest
    pathParams: never
    queryParams: never
    headerParams: never
    response: PostGeoPricesMutationResponse
    client: {
        parameters: Partial<Parameters<PostGeoPricesClient>[0]>
        return: Awaited<ReturnType<PostGeoPricesClient>>
    }
}
/**
 * @description Получение рекомендованной цены
 * @summary Рекомендованная цена
 * @link /geo/prices
 */
export function usePostGeoPrices(
    options: {
        mutation?: UseMutationOptions<
            PostGeoPrices['response'],
            PostGeoPrices['error'],
            {
                data: PostGeoPrices['request']
            }
        >
        client?: PostGeoPrices['client']['parameters']
    } = {}
) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {}

    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<
                PostGeoPrices['data'],
                PostGeoPrices['error'],
                PostGeoPrices['request']
            >({
                method: 'post',
                url: `/geo/prices`,
                data,
                ...clientOptions,
            })

            return res.data
        },
        ...mutationOptions,
    })
}
