import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    GetGeoCouriers400,
    GetGeoCouriersQueryParams,
    GetGeoCouriersQueryResponse,
} from '../entities/GetGeoCouriers'

type GetGeoCouriersClient = typeof client<GetGeoCouriersQueryResponse, GetGeoCouriers400, never>
type GetGeoCouriers = {
    data: GetGeoCouriersQueryResponse
    error: GetGeoCouriers400
    request: never
    pathParams: never
    queryParams: GetGeoCouriersQueryParams
    headerParams: never
    response: GetGeoCouriersQueryResponse
    client: {
        parameters: Partial<Parameters<GetGeoCouriersClient>[0]>
        return: Awaited<ReturnType<GetGeoCouriersClient>>
    }
}
export const getGeoCouriersQueryKey = (params: GetGeoCouriers['queryParams']) =>
    [{ url: '/geo/couriers' }, ...(params ? [params] : [])] as const
export type GetGeoCouriersQueryKey = ReturnType<typeof getGeoCouriersQueryKey>
export function getGeoCouriersQueryOptions(
    params: GetGeoCouriers['queryParams'],
    options: GetGeoCouriers['client']['parameters'] = {}
) {
    const queryKey = getGeoCouriersQueryKey(params)

    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetGeoCouriers['data'], GetGeoCouriers['error']>({
                method: 'get',
                url: `/geo/couriers`,
                params,
                ...options,
            })

            return res.data
        },
    })
}
/**
 * @description Получение локаций курьеров рядом
 * @summary Курьеры рядом
 * @link /geo/couriers
 */
export function useGetGeoCouriers<
    TData = GetGeoCouriers['response'],
    TQueryData = GetGeoCouriers['response'],
    TQueryKey extends QueryKey = GetGeoCouriersQueryKey,
>(
    params: GetGeoCouriers['queryParams'],
    options: {
        query?: Partial<
            QueryObserverOptions<
                GetGeoCouriers['response'],
                GetGeoCouriers['error'],
                TData,
                TQueryData,
                TQueryKey
            >
        >
        client?: GetGeoCouriers['client']['parameters']
    } = {}
): UseQueryResult<TData, GetGeoCouriers['error']> & {
    queryKey: TQueryKey
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {}
    const queryKey = queryOptions?.queryKey ?? getGeoCouriersQueryKey(params)
    const query = useQuery({
        ...(getGeoCouriersQueryOptions(params, clientOptions) as unknown as QueryObserverOptions),
        queryKey,
        ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
    }) as UseQueryResult<TData, GetGeoCouriers['error']> & {
        queryKey: TQueryKey
    }
    query.queryKey = queryKey as TQueryKey

    return query
}
