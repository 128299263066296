import React from 'react'
import { Checkbox, InputMessage } from '@dostavkee/web-ui'
import clsx from 'clsx'
import { Controller, useFormContext } from 'react-hook-form'

import { useAuth } from '@/shared/hooks'

import { OrderFormValues } from './order-form'
import styles from './order-form-options.module.scss'
import { OrderFormTitle } from './order-form-title'

const MAX_WEIGHTS = [1, 5, 10, 15, 20]

export const OrderFormOptions = () => {
    const { control } = useFormContext<OrderFormValues>()
    const { profile } = useAuth()
    const auto_couriers_enabled = profile?.user.city.options.auto_couriers_enabled

    return (
        <div className={styles['order-form-options']}>
            <Controller
                control={control}
                name='options.max_weight'
                render={({ field, fieldState }) => (
                    <div>
                        <OrderFormTitle>Вес посылки</OrderFormTitle>
                        <div className={styles['order-form-weight-list']}>
                            {MAX_WEIGHTS.map((weight) => (
                                <button
                                    key={weight}
                                    type='button'
                                    className={clsx(styles['chip-button'], {
                                        [styles['chip-button--active']]: field.value === weight,
                                        [styles['chip-button--invalid']]: fieldState.invalid,
                                    })}
                                    onClick={() => field.onChange(weight)}
                                >
                                    <span>до {weight} кг</span>
                                </button>
                            ))}
                        </div>
                        {fieldState.error?.message && (
                            <InputMessage variant='error'>{fieldState.error?.message}</InputMessage>
                        )}
                    </div>
                )}
                rules={{
                    required: 'Выберите вес посылки',
                }}
            />
            <div>
                <OrderFormTitle>Дополнительные опции</OrderFormTitle>
                <div className={styles['flags']}>
                    <Controller
                        control={control}
                        name='options.door_to_door'
                        render={({ field: { value, ...field }, fieldState }) => (
                            <Checkbox
                                checked={Boolean(value)}
                                isInvalid={fieldState.invalid}
                                {...field}
                            >
                                От двери до двери
                            </Checkbox>
                        )}
                    />
                    {auto_couriers_enabled && (
                        <Controller
                            control={control}
                            name='options.need_car'
                            render={({ field: { value, ...field }, fieldState }) => (
                                <Checkbox
                                    checked={Boolean(value)}
                                    isInvalid={fieldState.invalid}
                                    {...field}
                                >
                                    Нужен только автокурьер
                                </Checkbox>
                            )}
                        />
                    )}
                    <Controller
                        control={control}
                        name='options.need_thermal_bag'
                        render={({ field: { value, ...field }, fieldState }) => (
                            <Checkbox
                                checked={Boolean(value)}
                                isInvalid={fieldState.invalid}
                                {...field}
                            >
                                Нужна термосумка
                            </Checkbox>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}
