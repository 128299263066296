import React, { forwardRef, ReactNode } from 'react'
import { isEmpty } from '@dostavkee/helpers'
import { useInput } from '@dostavkee/react-hooks'
import clsx from 'clsx'

import { InputMessage } from '../input-message'
import styles from './input.module.scss'

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
    isInvalid?: boolean
    errorMessage?: string
    label?: string
    hint?: string | ReactNode
    prefixEl?: React.ReactNode
    postfixEl?: React.ReactNode
    value?: string | number | null
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            isInvalid,
            errorMessage,
            value = '',
            label,
            hint,
            prefixEl,
            postfixEl,
            className,
            ...props
        },
        forwardedRef
    ) => {
        const { handleBlur, handleFocus, isFocused, inputRef, setIsFocused } = useInput({
            ...props,
            ref: forwardedRef,
        })

        return (
            <div className={styles['input-wrapper']}>
                <div
                    className={clsx(styles['input-block'], className, {
                        [styles['input-block--invalid']]: isInvalid,
                        [styles['input-block--focused']]: isFocused,
                        [styles['input-block--has-prefix']]: Boolean(prefixEl),
                        [styles['input-block--has-postfix']]: Boolean(postfixEl),
                        [styles['input-block--disabled']]: Boolean(props.disabled),
                        [styles['input-block--button']]: Boolean(props.type === 'button'),
                    })}
                    onClick={(event) => {
                        if (typeof props.onClick === 'function') {
                            props.onClick(event as React.MouseEvent<HTMLInputElement>)
                        }
                        inputRef.current?.focus()
                    }}
                >
                    {prefixEl && prefixEl}
                    <input
                        ref={inputRef}
                        aria-disabled={props.disabled}
                        value={value ?? ''}
                        className={clsx(styles['input'], {
                            [styles['input--no-label']]: !label,
                        })}
                        onAnimationStart={(event) => {
                            if (event.animationName === styles['on-auto-fill-start']) {
                                setIsFocused(true)
                            }
                        }}
                        {...props}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />
                    {label && (
                        <label
                            htmlFor={props?.id}
                            className={clsx(styles['label'], {
                                [styles['label--focused']]:
                                    isFocused || !isEmpty(value) || typeof value === 'number',
                            })}
                        >
                            {label}
                        </label>
                    )}
                    {postfixEl && postfixEl}
                </div>

                {errorMessage ? (
                    <InputMessage variant='error'>{errorMessage}</InputMessage>
                ) : typeof hint === 'string' ? (
                    <InputMessage variant='info'>{hint}</InputMessage>
                ) : (
                    hint
                )}
            </div>
        )
    }
)

Input.displayName = 'Input'
