import { useCallback, useState } from 'react'
import { storage } from '@dostavkee/helpers'

export const useStorage = <T>(key: string): [T, (value: T | ((value: T) => T)) => T] => {
    const initialValue = storage.getItemFromStorage(key) as T
    const [storedValue, setStoredValue] = useState<T>(initialValue)

    const setValue = useCallback(
        (value: T | ((value: T) => T)) => {
            const newValue = value instanceof Function ? value(storedValue) : value
            storage.setItemToStorage<T>(key, newValue)
            setStoredValue(newValue)

            return newValue
        },
        [key, storedValue]
    )

    return [storedValue, setValue]
}
