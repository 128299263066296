import React, { FC, useMemo } from 'react'
import { moneyFormatter } from '@dostavkee/helpers'
import { Button } from '@dostavkee/web-ui'
import { useFormContext } from 'react-hook-form'

import { useBalanceFetch } from '@/routes/_dashboard/-hooks/use-balance-fetch'
import { useAuth, usePayment } from '@/shared/hooks'

import { useRecommendedPrice } from './hooks/use-recommended-price'
import { OrderFormValues } from './order-form'
import styles from './order-form-submit-button.module.scss'

const PAYMENT_MIN_AMOUNT = __GLOBAL__.VITE_PAYMENT_MIN_AMOUNT ?? 1000

interface OrderFormSubmitButtonProps {}

export const OrderFormSubmitButton: FC<OrderFormSubmitButtonProps> = () => {
    const { cityOptions } = useAuth()
    const { recommendedPrice } = useRecommendedPrice()
    const { handleOpenPaymentModal } = usePayment()
    const {
        formState: { isSubmitting },
    } = useFormContext<OrderFormValues>()
    const { balance, isLoading: isLoadingBalance } = useBalanceFetch({
        query: {
            networkMode: 'offlineFirst',
        },
    })

    const cityMinPrice = useMemo(() => {
        if (!cityOptions) {
            return 0
        }

        return cityOptions.recprice.recprice_minprice
    }, [cityOptions])

    const insufficientBalance = useMemo(() => {
        if (!balance?.amount || balance?.amount < cityMinPrice) {
            return `Пополните баланс на сумму от ${moneyFormatter(PAYMENT_MIN_AMOUNT)}, для оплаты доставки`
        }

        if (recommendedPrice?.min_price && balance.amount < recommendedPrice.min_price) {
            return `Минимальная сумма заказа: ${moneyFormatter(recommendedPrice?.min_price)}`
        }

        return false
    }, [balance?.amount, cityMinPrice, recommendedPrice?.min_price])

    if (!isLoadingBalance && insufficientBalance) {
        return (
            <Button fullWidth variant='error' onClick={handleOpenPaymentModal}>
                <div className={styles['no-balance-button']}>
                    Недостаточно средств
                    <div>{insufficientBalance}</div>
                </div>
            </Button>
        )
    }

    return (
        <Button fullWidth isLoading={isSubmitting} type='submit'>
            Создать заказ
        </Button>
    )
}
