import { atom, useAtom } from 'jotai'

const isAddressModalOpen = atom(false)

export const useAddressModal = () => {
    const [isOpen, setIsOpen] = useAtom(isAddressModalOpen)

    const onOpen = () => setIsOpen(true)
    const onClose = () => setIsOpen(false)

    return { isOpen, onOpen, onClose }
}
