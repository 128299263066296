import {
    GetOrders400,
    GetOrdersQueryParams,
    GetOrdersQueryResponse,
} from '@dostavkee/contracts-console/entities'
import { GetOrdersQueryKey, useGetOrders } from '@dostavkee/contracts-console/hooks'
import { QueryObserverOptions } from '@tanstack/react-query'

interface UseOrdersFetchProps {
    params: GetOrdersQueryParams
    query?: Partial<
        QueryObserverOptions<
            GetOrdersQueryResponse,
            GetOrders400,
            GetOrdersQueryResponse,
            GetOrdersQueryResponse,
            GetOrdersQueryKey
        >
    >
}

// TODO: Remove isFetching for optimization re-rendering
export const useOrdersFetch = (props: UseOrdersFetchProps) => {
    const { data, isLoading, isFetching, isPlaceholderData } = useGetOrders(props.params, {
        query: props.query,
    })

    return {
        orders: data?.orders,
        total: data?.total,
        isLoading: isLoading,
        isFetching: isFetching,
        isPlaceholderData: isPlaceholderData,
    }
}
