import React, { FC, useEffect, useMemo } from 'react'
import { useDeviceDetection } from '@dostavkee/react-hooks'
import Leaflet from 'leaflet'
import { useFormContext, useWatch } from 'react-hook-form'
import { Marker, Polyline, useMap } from 'react-leaflet'

import svgIconPointA from '@/assets/point-color-blue.svg'
import svgIconPointB from '@/assets/point-color-green.svg'

import { useRecommendedPrice } from './hooks/use-recommended-price'
import { OrderFormValues } from './order-form'
import { OrderFormMapCouriers } from './order-form-map-couriers'

const iconPointA = new Leaflet.Icon({
    iconUrl: svgIconPointA,
    iconRetinaUrl: svgIconPointA,
    iconSize: new Leaflet.Point(30, 30),
})

const iconPointB = new Leaflet.Icon({
    iconUrl: svgIconPointB,
    iconRetinaUrl: svgIconPointB,
    iconSize: new Leaflet.Point(30, 30),
})

interface OrderFormMapProps {}

export const OrderFormMap: FC<OrderFormMapProps> = () => {
    const map = useMap()
    const { control } = useFormContext<OrderFormValues>()
    const { isMobile } = useDeviceDetection()
    const { recommendedPrice } = useRecommendedPrice()

    const points = useWatch({ name: 'points', control })
    const sender = useWatch({ name: 'sender', control })

    const positions = useMemo(() => {
        if (!recommendedPrice?.geometry?.length) {
            return null
        }

        const geometry = recommendedPrice?.geometry?.reduce((route, line) => {
            const selection = line
                .replace('LINESTRING(', '')
                .replace(')', '')
                .split(', ')
                .map((coordinates) => {
                    const [longitude, latitude] = coordinates
                        .split(' ')
                        .map((coordinate) => Number.parseFloat(coordinate))

                    return [latitude, longitude]
                })

            return [...route, ...selection]
        }, [] as number[][])

        return geometry
    }, [recommendedPrice?.geometry])

    const markers = useMemo(
        () =>
            points
                .filter((point) => Boolean(point.location?.lat && point.location?.lng))
                .map((point) => point.location!),
        [points]
    )

    const bounds = useMemo(() => {
        if (!markers?.length) {
            return
        }

        const bounds = Leaflet.latLngBounds(
            Leaflet.latLng(markers[0].lat, markers[0].lng),
            Leaflet.latLng(markers[0].lat, markers[0].lng)
        )

        if (markers.length === 1) {
            return bounds
        }

        for (let index = 1; index < markers.length; index++) {
            bounds.extend(Leaflet.latLng(markers[index].lat, markers[index].lng))
        }

        return bounds
    }, [markers])

    useEffect(() => {
        if (bounds) {
            map.fitBounds(bounds, {
                maxZoom: 15,
                padding: [40, 40],
            })
        }
    }, [bounds, isMobile, map])

    return (
        <>
            {Boolean(markers?.length) &&
                markers?.map((marker, index) => (
                    <Marker
                        key={index}
                        icon={index === 0 ? iconPointA : iconPointB}
                        position={[marker.lat, marker.lng]}
                    />
                ))}

            {markers?.length > 1 && Boolean(positions?.length) && (
                <Polyline
                    // TODO: Fix types
                    // @ts-ignore
                    positions={positions}
                    pathOptions={{
                        color: 'var(--background-accent)',
                        weight: 4,
                    }}
                />
            )}

            {sender?.id && points?.[0]?.location.lat && points?.[0]?.location?.lng && (
                <OrderFormMapCouriers
                    addressId={sender.id}
                    lat={points[0].location.lat}
                    lng={points[0].location.lng}
                />
            )}
        </>
    )
}
