import { useCallback } from 'react'
import { getUsersLogout } from '@dostavkee/contracts-console/api'
import { AXIOS_INSTANCE } from '@dostavkee/contracts-console/axios-client'
import { useGetUsersProfile } from '@dostavkee/contracts-console/hooks'
import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import toast from 'react-hot-toast'

import { RegisterUserPhoneFormValues } from '@/routes/_auth/register/-ui'

import {
    ORDER_FORM_PERSISTED_SENDER_STORAGE_KEY,
    ORDER_FORM_PERSISTED_STORAGE_KEY,
} from '../constants'

export interface Auth {
    access_token: string
    email?: string
}

export interface RegisteredUser {
    isEmailCodeRequested: boolean
    isEmailCodeVerified: boolean
    timestampEmailRequested?: number
    registrationUserId?: string
    email?: string
    password?: string
    formValues?: RegisterUserPhoneFormValues
}

const persistentStorage = createJSONStorage<Auth | undefined>(() => localStorage)

const transientStorage = createJSONStorage<RegisteredUser | undefined>(() => sessionStorage)

const authAtom = atomWithStorage<Auth | undefined>(
    '@dostavkee/auth',
    undefined,
    persistentStorage,
    {
        getOnInit: true,
    }
)

const registerUserAtom = atomWithStorage<RegisteredUser | undefined>(
    '@dostavkee/not-registered-user',
    {
        isEmailCodeRequested: false,
        isEmailCodeVerified: false,
        timestampEmailRequested: undefined,
        registrationUserId: undefined,
        formValues: undefined,
    },
    transientStorage
)

export const useAuth = () => {
    const [auth, setAuth] = useAtom(authAtom)
    const [registerUser, setRegisterUser] = useAtom(registerUserAtom)

    const {
        data: profile,
        isFetching,
        isLoading,
    } = useGetUsersProfile({
        query: {
            refetchOnMount: (query) => {
                return Boolean(auth?.access_token) && !query.state.data?.user?.id
            },
            refetchOnWindowFocus: true,
            refetchOnReconnect: false,
            enabled: Boolean(auth?.access_token),
            staleTime: Number.POSITIVE_INFINITY,
        },
    })

    const isAuthenticated = Boolean(auth?.access_token)

    const isUserRegistered: boolean = Boolean(auth?.access_token)

    const isCompanyRegistered: boolean = Boolean(profile?.user?.company?.id)

    const isCategorySelected: boolean = Boolean(profile?.user?.company?.category?.id)

    const isFullyRegistered = isUserRegistered && isCompanyRegistered && isCategorySelected

    const queryClient = useQueryClient()

    /**
     * Handle logout
     */
    const onLogout = useCallback(async () => {
        const toastId = toast.loading('Выполняем выход...')

        try {
            /**
             * Clear refresh_token cookie
             */
            await getUsersLogout()

            /**
             * Clear the global auth state
             */
            setAuth(undefined)

            /**
             * Clear the axios instance headers
             */
            AXIOS_INSTANCE.defaults.headers.Authorization = ''

            /**
             * Clear of registered user
             */
            setRegisterUser(undefined)

            /**
             * Clear webim authorized visitor
             */
            webim_visitor = null
            webim.api.onProvidedVisitorChanged()

            /**
             * Clear persisted order form data
             */
            localStorage.removeItem(ORDER_FORM_PERSISTED_STORAGE_KEY)
            localStorage.removeItem(ORDER_FORM_PERSISTED_SENDER_STORAGE_KEY)

            /**
             * Clear the tanstack-query cache
             */
            setTimeout(() => {
                queryClient.clear()
            }, 400)

            toast.success('Вы успешно вышли из аккаунта', {
                id: toastId,
            })
        } catch (error) {
            Sentry.captureException(error)
            toast.error('Ошибка при выполнении выхода', {
                id: toastId,
            })
        }
    }, [queryClient, setAuth, setRegisterUser])

    return {
        auth,
        setAuth,
        profile,
        companyId: profile?.user?.company?.id,
        company: profile?.user?.company,
        cityOptions: profile?.user?.city?.options,
        isAuthenticated,
        isUserRegistered,
        isCompanyRegistered,
        isCategorySelected,
        isFullyRegistered,
        isFetching,
        isLoading,
        onLogout,
        registerUser,
        setRegisterUser,
    }
}
