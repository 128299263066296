import { useEffect, useState } from 'react'

export const useDebounce = <T>(value: T, timeout: number): T => {
    const [state, setState] = useState(value)

    useEffect(() => {
        const tick = setTimeout(() => setState(value), timeout)

        return () => clearTimeout(tick)
    }, [value, timeout])

    if (timeout <= 0) return value

    return state
}
