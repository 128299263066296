/* eslint-disable unicorn/no-useless-undefined */
import { AddressModel } from '@dostavkee/contracts-console/entities'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import {
    ORDER_FORM_PERSISTED_SENDER_STORAGE_KEY,
    ORDER_FORM_PERSISTED_STORAGE_KEY,
} from '@/shared/constants'

import { OrderFormValues } from '../order-form'

const persistedOrderFormValuesAtom = atomWithStorage<OrderFormValues | undefined>(
    ORDER_FORM_PERSISTED_STORAGE_KEY,
    undefined
)

const persistedSenderAtom = atomWithStorage<AddressModel | undefined>(
    ORDER_FORM_PERSISTED_SENDER_STORAGE_KEY,
    undefined
)

export const useFormPersist = () => {
    const [persistedOrderFormValues, setPersistedOrderFormValues] = useAtom(
        persistedOrderFormValuesAtom
    )
    const [persistedSender, setPersistedSender] = useAtom(persistedSenderAtom)

    return {
        persistedOrderFormValues: persistedOrderFormValues,
        setPersistedOrderFormValues: setPersistedOrderFormValues,
        persistedSender: persistedSender,
        setPersistedSender: setPersistedSender,
    }
}
