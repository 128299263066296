import React, { FC, useEffect } from 'react'
import { getMaxLengthErrorMessage } from '@dostavkee/helpers'
import { usePrevious } from '@dostavkee/react-hooks'
import { Autocomplete, Button, Icon, IconAdd, Input, InputPhone, TextArea } from '@dostavkee/web-ui'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'

import { useAddresses } from '@/entities/addresses/api/queries/use-addresses-suggestions'

import { DEFAULT_VALUES_ORDER_FORM_POINT, OrderFormValues } from './order-form'
import styles from './order-form-points.module.scss'

const MAX_COUNT_POINTS = 4

interface OrderFormPointsProps {
    cityId: string
}

const getPointIconText = (index: number, count: number) => {
    if (index === 0) {
        return 'A'
    }

    if (index === 1 && count === 2) {
        return 'B'
    }

    return `B${index}`
}

export const OrderFormPoints: FC<OrderFormPointsProps> = ({ cityId }) => {
    const { control, setValue, clearErrors } = useFormContext<OrderFormValues>()
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'points',
        keyName: 'fieldId',
    })

    const { suggestedAddresses, loadAddressesOptions } = useAddresses({ cityId })

    const sender = useWatch({ name: 'sender', control, exact: true })
    const previousSender = usePrevious(sender)
    const firstPoint = useWatch({ name: 'points.0', control, exact: true })

    useEffect(() => {
        if (sender && !firstPoint?.address_name) {
            setValue('points.0', {
                address_name: sender.address,
                ...sender,
            })
        }
    }, [firstPoint?.address_name, sender, setValue])

    useEffect(() => {
        if (previousSender?.id && sender?.id && previousSender?.id !== sender?.id) {
            setValue('points.0', {
                address_name: sender?.address,
                ...sender,
            })
        }
    }, [previousSender?.id, sender, setValue])

    const comment = useWatch({ name: 'comment', control })

    useEffect(() => {
        if (previousSender?.id && sender?.id && previousSender?.id !== sender?.id) {
            setValue('comment', sender?.comment)
        }
    }, [previousSender?.id, sender, setValue])

    useEffect(() => {
        if (sender?.comment && comment === undefined) {
            setValue('comment', sender?.comment)
        }
    }, [comment, sender?.comment, setValue])

    return (
        <div className={styles['order-form-points']}>
            {fields.map((field, index) => {
                const isSenderPoint = index === 0
                const isSenderNotSelected = isSenderPoint && !sender?.id

                return (
                    <div key={field.fieldId} className={styles['order-form-points__card-wrapper']}>
                        <div className={styles['order-form-points__card']}>
                            <div>
                                <Controller
                                    control={control}
                                    name={`points.${index}.address_name`}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            {...field}
                                            autoComplete='off'
                                            disabled={isSenderNotSelected}
                                            errorMessage={fieldState.error?.message}
                                            id={`address_name-${index}`}
                                            isInvalid={fieldState.invalid}
                                            label={isSenderPoint ? 'Откуда' : 'Куда'}
                                            loadOptions={loadAddressesOptions}
                                            type='text'
                                            prefixEl={
                                                <span
                                                    className={
                                                        styles['order-form-points__icon-address']
                                                    }
                                                >
                                                    {getPointIconText(index, fields.length)}
                                                </span>
                                            }
                                            onSelect={(option) => {
                                                const selectedAddress =
                                                    suggestedAddresses.current?.find(
                                                        (item) => option?.id === item.id
                                                    )

                                                if (selectedAddress) {
                                                    field.onChange(
                                                        selectedAddress.full_address_name ??
                                                            selectedAddress.address
                                                    )

                                                    setValue(`points.${index}.location`, {
                                                        lat: selectedAddress.location?.lat!,
                                                        lng: selectedAddress.location?.lon!,
                                                    })
                                                    if (fieldState.invalid) {
                                                        clearErrors(`points.${index}.address_name`)
                                                    }
                                                } else {
                                                    field.onChange('')

                                                    setValue(`points.${index}.location`, {
                                                        lat: 0,
                                                        lng: 0,
                                                    })
                                                }
                                            }}
                                        />
                                    )}
                                    rules={{
                                        required: 'Выберите адрес из списка',
                                    }}
                                />
                            </div>
                            <div className={styles['order-form-points__card-row']}>
                                <Controller
                                    control={control}
                                    name={`points.${index}.entry`}
                                    render={({ field, fieldState }) => (
                                        <Input
                                            autoComplete='off'
                                            disabled={isSenderNotSelected}
                                            errorMessage={fieldState.error?.message}
                                            id={`entry-${index}`}
                                            isInvalid={fieldState.invalid}
                                            label='Подъезд'
                                            type='text'
                                            {...field}
                                        />
                                    )}
                                    rules={{
                                        maxLength: {
                                            value: 10,
                                            message: getMaxLengthErrorMessage(10),
                                        },
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name={`points.${index}.room`}
                                    render={({ field, fieldState }) => (
                                        <Input
                                            autoComplete='off'
                                            disabled={isSenderNotSelected}
                                            errorMessage={fieldState.error?.message}
                                            id={`room-${index}`}
                                            isInvalid={fieldState.invalid}
                                            label='Кв/офис'
                                            type='text'
                                            {...field}
                                        />
                                    )}
                                    rules={{
                                        maxLength: {
                                            value: 10,
                                            message: getMaxLengthErrorMessage(10),
                                        },
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name={`points.${index}.floor`}
                                    render={({ field, fieldState }) => (
                                        <Input
                                            autoComplete='off'
                                            disabled={isSenderNotSelected}
                                            errorMessage={fieldState.error?.message}
                                            id={`floor-${index}`}
                                            isInvalid={fieldState.invalid}
                                            label='Этаж'
                                            type='text'
                                            {...field}
                                        />
                                    )}
                                    rules={{
                                        maxLength: {
                                            value: 10,
                                            message: getMaxLengthErrorMessage(10),
                                        },
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name={`points.${index}.intercom`}
                                    render={({ field, fieldState }) => (
                                        <Input
                                            autoComplete='off'
                                            disabled={isSenderNotSelected}
                                            errorMessage={fieldState.error?.message}
                                            id={`intercom-${index}`}
                                            isInvalid={fieldState.invalid}
                                            label='Домофон'
                                            type='text'
                                            {...field}
                                        />
                                    )}
                                    rules={{
                                        maxLength: {
                                            value: 10,
                                            message: getMaxLengthErrorMessage(10),
                                        },
                                    }}
                                />
                            </div>
                            {isSenderPoint && (
                                <Controller
                                    control={control}
                                    name='comment'
                                    render={({ field, fieldState }) => (
                                        <TextArea
                                            autoComplete='off'
                                            errorMessage={fieldState.error?.message}
                                            id='comment'
                                            inputMode='text'
                                            isInvalid={fieldState.invalid}
                                            label='Комментарий для курьера'
                                            {...field}
                                        />
                                    )}
                                    rules={{
                                        maxLength: {
                                            value: 70,
                                            message: getMaxLengthErrorMessage(70),
                                        },
                                    }}
                                />
                            )}
                            {!isSenderPoint && (
                                <div className={styles['order-form-points__phone']}>
                                    <Controller
                                        control={control}
                                        name={`points.${index}.phone`}
                                        render={({ field, fieldState }) => (
                                            <InputPhone
                                                errorMessage={fieldState.error?.message}
                                                id={`phone-${index}`}
                                                isInvalid={fieldState.invalid}
                                                label='Телефон получателя'
                                                {...field}
                                            />
                                        )}
                                        rules={{
                                            required: 'Обязательное поле',
                                            validate: (value) => {
                                                if (value.length !== 11) {
                                                    return 'Неверный формат номера телефона'
                                                }
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {fields.length > 2 && index !== 0 && (
                            <Button
                                variant='ghost'
                                prefixEl={
                                    <Icon
                                        color='var(--extensions-text-and-icon-error)'
                                        icon='delete'
                                        size={20}
                                    />
                                }
                                style={{
                                    color: 'var(--extensions-text-and-icon-error)',
                                    marginTop: -4,
                                }}
                                onClick={() => remove(index)}
                            >
                                Удалить адрес
                            </Button>
                        )}
                    </div>
                )
            })}

            {fields.length <= MAX_COUNT_POINTS && (
                <Button
                    prefixEl={<IconAdd color='var(--text-and-icon-accent)' size={20} />}
                    variant='ghost'
                    style={{
                        color: 'var(--text-and-icon-accent)',
                    }}
                    onClick={() => append(DEFAULT_VALUES_ORDER_FORM_POINT)}
                >
                    Добавить адрес
                </Button>
            )}
        </div>
    )
}
