import React, { useEffect } from 'react'
import { moneyFormatter } from '@dostavkee/helpers'
import { type NumberFormatEventDetail, useNumberFormat } from '@react-input/number-format'

import { Input, InputProps } from '../input/input'

export interface InputMoneyProps extends Omit<InputProps, 'onChange'> {
    onChange: (value: number) => void
}

export const InputMoney: React.FC<InputMoneyProps> = React.forwardRef<
    HTMLInputElement,
    InputMoneyProps
>(({ onChange, ...props }, ref) => {
    const [detail, setDetail] = React.useState<NumberFormatEventDetail | null>(null)

    const maskRef = useNumberFormat({
        locales: 'ru-RU',
        format: 'currency',
        currency: 'RUB',
        maximumFractionDigits: 0,
        onNumberFormat: (event) => {
            let currentNumber: number = event.detail.number
            let currentValue: string = event.detail.value

            currentValue = currentValue.replaceAll(/[,.]/g, '')

            if (currentNumber <= 0) {
                currentNumber = Math.abs(currentNumber)
                currentValue = currentValue.replaceAll('-', '')
            }

            setDetail({ number: currentNumber, value: currentValue })
            onChange(currentNumber)
        },
    })

    const value = React.useMemo(() => {
        if (!props.value) {
            return
        }

        if (
            typeof props.value === 'number' &&
            typeof detail?.number === 'number' &&
            detail.number !== props.value
        ) {
            return moneyFormatter(props.value)
        }

        if (detail?.value) {
            return detail.value
        }

        if (props.value && typeof props.value === 'number') {
            return moneyFormatter(props.value)
        }

        return props.value
    }, [detail, props.value])

    useEffect(() => {
        if (!ref) {
            return
        }

        if (typeof ref === 'function') {
            ref(maskRef.current)
        } else {
            ref.current = maskRef.current
        }
    }, [maskRef, ref])

    return (
        <Input
            ref={maskRef}
            autoComplete='off'
            inputMode='numeric'
            type='text'
            {...props}
            value={value}
            onChange={() => {}}
        />
    )
})

InputMoney.displayName = 'InputMoney'
