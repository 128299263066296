import React, { FC } from 'react'

import styles from './order-form-title.module.scss'

interface OrderFormTitleProps {
    children: React.ReactNode
}

export const OrderFormTitle: FC<OrderFormTitleProps> = ({ children }) => {
    return <div className={styles['order-form-title']}>{children}</div>
}
