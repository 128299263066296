import React from 'react'
import { getMaxLengthErrorMessage } from '@dostavkee/helpers'
import { Input } from '@dostavkee/web-ui'
import { Controller, useFormContext } from 'react-hook-form'

import { OrderFormValues } from './order-form'
import { OrderFormCommentVariants } from './order-form-comment-variants'
import { OrderFormTitle } from './order-form-title'

export const OrderFormComment = () => {
    const { control, setValue, resetField } = useFormContext<OrderFormValues>()

    return (
        <div>
            <OrderFormTitle>Что везём</OrderFormTitle>
            <Controller
                control={control}
                name='what_take'
                render={({ field, fieldState }) => (
                    <Input
                        autoComplete='off'
                        errorMessage={fieldState.error?.message}
                        id='what_take'
                        inputMode='text'
                        isInvalid={fieldState.invalid}
                        label='Что в посылке?'
                        {...field}
                    />
                )}
                rules={{
                    required: 'Обязательное поле',
                    maxLength: {
                        value: 20,
                        message: getMaxLengthErrorMessage(20),
                    },
                }}
            />
            <OrderFormCommentVariants
                onChange={(value) => {
                    /**
                     * Reset field to clear error message
                     */
                    resetField('what_take')

                    /**
                     * Set value selected from variants
                     */
                    setValue('what_take', value)
                }}
            />
        </div>
    )
}
