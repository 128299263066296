const isBrowser = typeof window !== 'undefined'

export const setItemToStorage = <T>(key: string, value: T): void => {
    if (!isBrowser) {
        return
    }

    try {
        window.localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
        console.warn(error)
    }
}

export const getItemFromStorage = <T>(key: string): T | undefined => {
    if (!isBrowser) {
        return undefined
    }

    try {
        const item = window.localStorage.getItem(key)

        if (!item) {
            return undefined
        }

        return JSON.parse(item)
    } catch {
        return undefined
    }
}
