import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    GetOrdersPublicOrderIdLocation400,
    GetOrdersPublicOrderIdLocationPathParams,
    GetOrdersPublicOrderIdLocationQueryResponse,
} from '../entities/GetOrdersPublicOrderIdLocation'

type GetOrdersPublicOrderIdLocationClient = typeof client<
    GetOrdersPublicOrderIdLocationQueryResponse,
    GetOrdersPublicOrderIdLocation400,
    never
>
type GetOrdersPublicOrderIdLocation = {
    data: GetOrdersPublicOrderIdLocationQueryResponse
    error: GetOrdersPublicOrderIdLocation400
    request: never
    pathParams: GetOrdersPublicOrderIdLocationPathParams
    queryParams: never
    headerParams: never
    response: GetOrdersPublicOrderIdLocationQueryResponse
    client: {
        parameters: Partial<Parameters<GetOrdersPublicOrderIdLocationClient>[0]>
        return: Awaited<ReturnType<GetOrdersPublicOrderIdLocationClient>>
    }
}
export const getOrdersPublicOrderIdLocationQueryKey = (
    orderId: GetOrdersPublicOrderIdLocationPathParams['order_id']
) => [{ url: '/orders/public/:order_id/location', params: { orderId: orderId } }] as const
export type GetOrdersPublicOrderIdLocationQueryKey = ReturnType<
    typeof getOrdersPublicOrderIdLocationQueryKey
>
export function getOrdersPublicOrderIdLocationQueryOptions(
    orderId: GetOrdersPublicOrderIdLocationPathParams['order_id'],
    options: GetOrdersPublicOrderIdLocation['client']['parameters'] = {}
) {
    const queryKey = getOrdersPublicOrderIdLocationQueryKey(orderId)

    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<
                GetOrdersPublicOrderIdLocation['data'],
                GetOrdersPublicOrderIdLocation['error']
            >({
                method: 'get',
                url: `/orders/public/${orderId}/location`,
                ...options,
            })

            return res.data
        },
    })
}
/**
 * @description Получение публичной локации курьера
 * @summary Получение публичной локации курьера
 * @link /orders/public/:order_id/location
 */
export function useGetOrdersPublicOrderIdLocation<
    TData = GetOrdersPublicOrderIdLocation['response'],
    TQueryData = GetOrdersPublicOrderIdLocation['response'],
    TQueryKey extends QueryKey = GetOrdersPublicOrderIdLocationQueryKey,
>(
    orderId: GetOrdersPublicOrderIdLocationPathParams['order_id'],
    options: {
        query?: Partial<
            QueryObserverOptions<
                GetOrdersPublicOrderIdLocation['response'],
                GetOrdersPublicOrderIdLocation['error'],
                TData,
                TQueryData,
                TQueryKey
            >
        >
        client?: GetOrdersPublicOrderIdLocation['client']['parameters']
    } = {}
): UseQueryResult<TData, GetOrdersPublicOrderIdLocation['error']> & {
    queryKey: TQueryKey
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {}
    const queryKey = queryOptions?.queryKey ?? getOrdersPublicOrderIdLocationQueryKey(orderId)
    const query = useQuery({
        ...(getOrdersPublicOrderIdLocationQueryOptions(
            orderId,
            clientOptions
        ) as unknown as QueryObserverOptions),
        queryKey,
        ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
    }) as UseQueryResult<TData, GetOrdersPublicOrderIdLocation['error']> & {
        queryKey: TQueryKey
    }
    query.queryKey = queryKey as TQueryKey

    return query
}
