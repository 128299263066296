import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconClearFilled: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM8.30603 9.78364C7.89799 9.37561 7.89799 8.71406 8.30603 8.30602C8.71406 7.89799 9.37561 7.89799 9.78364 8.30602L12.0004 10.5228L14.2165 8.30673C14.6245 7.8987 15.2861 7.8987 15.6941 8.30673C16.1021 8.71476 16.1021 9.37631 15.6941 9.78435L13.478 12.0004L15.6941 14.2165C16.1021 14.6245 16.1021 15.2861 15.6941 15.6941C15.2861 16.1021 14.6245 16.1021 14.2165 15.6941L12.0004 13.478L9.78364 15.6948C9.37561 16.1028 8.71406 16.1028 8.30602 15.6948C7.89799 15.2868 7.89799 14.6252 8.30602 14.2172L10.5228 12.0004L8.30603 9.78364Z'
                fill={color}
                fillRule='evenodd'
            />
        </svg>
    )
}
