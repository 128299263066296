import React, { FC } from 'react'
import { PointModel } from '@dostavkee/contracts-console/entities'
import { PointPublicInfoModel } from '@dostavkee/contracts-external/entities'
import { addressFormatter, phoneFormatter } from '@dostavkee/helpers'
import { ReactNode } from '@tanstack/react-router'
import clsx from 'clsx'

import styles from './order-addresses-points.module.scss'

export interface OrderAddressesPointsProps {
    points: PointModel[] | PointPublicInfoModel[]
    // withTitle?: ReactNode
    withPhones?: boolean
    className?: string
    headerLeftSlot?: ReactNode
    headerRightSlot?: ReactNode
}

export const OrderAddressesPoints: FC<OrderAddressesPointsProps> = ({
    points,
    withPhones = false,
    className,
    // withTitle = false,
    headerRightSlot,
    headerLeftSlot,
}) => {
    return (
        <section className={clsx([styles['order-addresses-points'], className && className])}>
            {(headerLeftSlot || headerRightSlot) && (
                <header className={styles['order-addresses-points__header']}>
                    {headerLeftSlot && <div>{headerLeftSlot}</div>}
                    {headerRightSlot && <div>{headerRightSlot}</div>}
                </header>
            )}
            {points.map((point, index) => (
                <div key={index} className={styles['item']}>
                    {'entry' in point
                        ? addressFormatter(point.address_name, point.entry, point.floor, point.room)
                        : point.address_name}
                    {withPhones && 'phone' in point && (
                        <a className={styles['item__phone']} href={`tel:+${point.phone}`}>
                            {phoneFormatter(point.phone)}
                        </a>
                    )}
                </div>
            ))}
        </section>
    )
}

OrderAddressesPoints.displayName = 'OrderAddressesPoints'
