import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconSearch: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M15.9418 17.36C14.5833 18.416 12.8763 19.0448 11.0224 19.0448C6.59176 19.0448 3 15.4531 3 11.0224C3 6.59176 6.59176 3 11.0224 3C15.4531 3 19.0448 6.59176 19.0448 11.0224C19.0448 12.8763 18.416 14.5833 17.36 15.9418L20.7063 19.2881C21.0979 19.6798 21.0979 20.3147 20.7063 20.7063C20.3147 21.0979 19.6797 21.0979 19.2881 20.7063L15.9418 17.36ZM17.0392 11.0224C17.0392 14.3454 14.3454 17.0392 11.0224 17.0392C7.69942 17.0392 5.0056 14.3454 5.0056 11.0224C5.0056 7.69942 7.69942 5.0056 11.0224 5.0056C14.3454 5.0056 17.0392 7.69942 17.0392 11.0224Z'
                fill={color}
                fillRule='evenodd'
            />
        </svg>
    )
}
