import React, { FC, useEffect } from 'react'
import { AddressModel } from '@dostavkee/contracts-console/entities'
import { addressFormatter } from '@dostavkee/helpers'
import { usePrevious } from '@dostavkee/react-hooks'
import { Alert, Button, Icon, IconAdd, Select } from '@dostavkee/web-ui'
import { SelectOption } from '@dostavkee/web-ui/src/select/select'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { useAddressModal } from '@/routes/_dashboard/addresses/-hooks/use-address-modal'

import { useFormPersist } from './hooks/use-form-persist'
import { OrderFormValues } from './order-form'
import styles from './order-form-sender.module.scss'

interface OrderFormSenderProps {
    addresses?: AddressModel[]
}

export const OrderFormSender: FC<OrderFormSenderProps> = ({ addresses }) => {
    const { control, setValue, getValues, clearErrors } = useFormContext<OrderFormValues>()
    const sender = useWatch({ name: 'sender', control })
    const { persistedSender, setPersistedSender } = useFormPersist()
    const { onOpen: onOpenAddressModal } = useAddressModal()

    const options: SelectOption[] =
        addresses?.map((address) => ({
            id: address.id,
            name: address.name,
            hint: addressFormatter(
                address.address,
                address.entry,
                address.floor,
                address.room,
                address.intercom,
                address.phone
            ),
        })) ?? []

    const firstAddress = addresses?.[0]
    const previousFirstAddress = usePrevious(firstAddress)

    /**
     * Set first address as sender if sender is not set
     */
    useEffect(() => {
        if (!sender && firstAddress) {
            setTimeout(() => {
                if (persistedSender?.id) {
                    setValue('sender', persistedSender)
                    setValue('comment', persistedSender?.comment ?? '')
                } else if (firstAddress?.id) {
                    setValue('sender', firstAddress)
                    setValue('comment', firstAddress?.comment ?? '')
                }
            }, 0)
        }
    }, [firstAddress, persistedSender, sender, setValue])

    /**
     * Set first address as sender if first address was changed
     */
    useEffect(() => {
        if (firstAddress && previousFirstAddress && previousFirstAddress.id !== firstAddress.id) {
            setValue('sender', firstAddress)
        }
    }, [firstAddress, previousFirstAddress, setValue])

    return (
        <>
            {!addresses?.length && (
                <>
                    <Alert
                        variant='error'
                        icon={
                            <Icon
                                color='var(--extensions-background-error)'
                                icon='warning-filled'
                            />
                        }
                    >
                        <div className={styles['no-address-alert']}>
                            <div>
                                Для оформления заказа, необходимо сохранить хотя бы один адрес
                                отправителя
                            </div>
                            <Button
                                color='white'
                                size='xs'
                                variant='secondary'
                                onClick={onOpenAddressModal}
                            >
                                Добавить адрес
                            </Button>
                        </div>
                    </Alert>
                </>
            )}
            <Controller
                control={control}
                name='sender'
                render={({ field, fieldState }) => (
                    <Select
                        errorMessage={fieldState.error?.message}
                        id='sender'
                        isInvalid={fieldState.invalid}
                        label='Адрес отправителя'
                        options={options}
                        footer={(_, setIsOpen) => (
                            <div
                                aria-label='Добавить новый адрес'
                                className={styles['add-address-button']}
                                role='button'
                                onClick={() => {
                                    setIsOpen(false)
                                    onOpenAddressModal()
                                }}
                            >
                                <IconAdd color='var(--text-and-icon-accent)' /> Добавить новый адрес
                            </div>
                        )}
                        {...field}
                        value={
                            options.find((item) => item.id === getValues('sender.id')) || undefined
                        }
                        onChange={(option) => {
                            const selectedAddress = addresses?.find((item) => item.id === option.id)

                            if (selectedAddress) {
                                setValue('sender', selectedAddress)
                                setPersistedSender(selectedAddress)

                                if (fieldState.invalid) {
                                    clearErrors('sender')
                                }
                            }
                        }}
                    />
                )}
                rules={{
                    required: 'Обязательное поле',
                }}
            />
        </>
    )
}
