import React, { forwardRef } from 'react'
import clsx from 'clsx'

import styles from './checkbox.module.scss'

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    isInvalid?: boolean
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    ({ children, isInvalid, ...props }, ref) => {
        return (
            <div className={styles['checkbox-container']}>
                <label role='checkbox'>
                    <input
                        ref={ref}
                        aria-disabled={props.disabled}
                        type='checkbox'
                        className={clsx(styles['checkbox-input'], {
                            [styles['checkbox-input-invalid']]: isInvalid,
                        })}
                        {...props}
                    />
                    <span aria-hidden className={styles['checkbox-square']} />
                    <span className={styles['checkbox-label']}>{children}</span>
                </label>
            </div>
        )
    }
)

Checkbox.displayName = 'Checkbox'
