import React, { lazy, memo, Suspense, useCallback, useEffect } from 'react'
import {
    AddressModel,
    ErrorStruct,
    LocationModel,
    OrderOptionsModel,
    ServiceOrderInfo,
    V1UserModel,
} from '@dostavkee/contracts-console/entities'
import { useStorage } from '@dostavkee/react-hooks'
import { Button, IconArrowLeft, IconArrowUp } from '@dostavkee/web-ui'
import { FormProvider, useForm } from 'react-hook-form'
import { uuidv7 } from 'uuidv7'

import { ERROR_CODES, ORDER_FORM_PERSISTED_STORAGE_KEY } from '@/shared/constants'
import { useAnalytics, useNavigateBack } from '@/shared/hooks'
import { Tapbar } from '@/shared/ui'

import { useRecommendedPrice } from './hooks/use-recommended-price'
import styles from './order-form.module.scss'
import { OrderFormComment } from './order-form-comment'
import { OrderFormMap } from './order-form-map'
import { OrderFormOptions } from './order-form-options'
import { OrderFormPersist } from './order-form-persist'
import { OrderFormPoints } from './order-form-points'
import { OrderFormPrice } from './order-form-price'
import { OrderFormSender } from './order-form-sender'
import { OrderFormSubmitButton } from './order-form-submit-button'

const ERROR_CODES_TO_GENERATING_NEW_IDEMPOTENCY_UUID = new Set([
    ERROR_CODES.DRIVEE_CREATION_ORDER,
    ERROR_CODES.ORDER_NO_ONE_IS_AVAILABLE_ERROR_CODE,
    ERROR_CODES.ORDER_UNATTRACTIVE_PRICE_ERROR_CODE,
    ERROR_CODES.ORDER_OFFER_A_HIGHER_FARE_ERROR_CODE,
    ERROR_CODES.ORDER_REQUEST_IS_NOT_ACCEPTED_ERROR_CODE,
])

const Map = lazy(async () => import('@/shared/ui/map').then((module) => ({ default: module.Map })))

export interface OrderFormProps {
    onSubmit: (values: OrderFormValues) => void
    onOrderCreateSuccess: () => void
    addresses: AddressModel[]
    user: V1UserModel
    initialValues?: Partial<OrderFormValues>
}

export interface OrderFormValues {
    id: string
    sender?: AddressModel
    comment?: string
    options: Omit<OrderOptionsModel, 'max_weight'> & {
        max_weight?: number | null
    }
    points: {
        address_name: string
        entry?: string | null
        floor?: string | null
        intercom?: string | null
        location: LocationModel
        phone: string
        room?: string | null
    }[]
    price: string
    info: ServiceOrderInfo
    what_take: string
    _timestamp?: number
}

export const DEFAULT_VALUES_ORDER_FORM_POINT = {
    address_name: '',
    phone: '',
    entry: null,
    floor: null,
    intercom: null,
    location: {
        lat: 0,
        lng: 0,
    },
    room: null,
}

export const DEFAULT_VALUES_ORDER_FORM_OPTIONS: OrderFormValues['options'] = {
    need_car: false,
    max_weight: null,
    need_thermal_bag: false,
    door_to_door: true,
}

export const DEFAULT_VALUES_ORDER_FORM: OrderFormValues = {
    id: '',
    comment: '',
    options: DEFAULT_VALUES_ORDER_FORM_OPTIONS,
    points: [DEFAULT_VALUES_ORDER_FORM_POINT, DEFAULT_VALUES_ORDER_FORM_POINT],
    price: '',
    info: { routes: undefined },
    what_take: '',
}

const PERSIST_TIMEOUT = 1000 * 60 * 10 // 10 minutes

export const OrderForm = memo<OrderFormProps>(
    ({ onSubmit, addresses, user, initialValues, onOrderCreateSuccess }: OrderFormProps) => {
        const navigateBack = useNavigateBack({ fallback: '/orders/active' })
        const analytics = useAnalytics()
        const { setRecommendedPrice } = useRecommendedPrice()

        const [persistedValues, setPersistedValues] = useStorage<OrderFormValues | undefined>(
            ORDER_FORM_PERSISTED_STORAGE_KEY
        )

        const getDefaultValues = useCallback(
            (persistedValues?: OrderFormValues) => {
                try {
                    if (initialValues) {
                        return {
                            ...initialValues,
                            id: uuidv7(),
                        }
                    }

                    if (!persistedValues?._timestamp) {
                        throw new Error('No persisted values')
                    }

                    const { _timestamp, ...values } = persistedValues

                    const currentTimestamp = Date.now()

                    const persistedValuesIsExpired = currentTimestamp - _timestamp > PERSIST_TIMEOUT

                    if (persistedValuesIsExpired) {
                        setPersistedValues(undefined)

                        throw new Error('Persisted values is expired')
                    }

                    return {
                        ...values,
                        id: uuidv7(),
                    }
                } catch {
                    return {
                        ...DEFAULT_VALUES_ORDER_FORM,
                        id: uuidv7(),
                    }
                }
            },
            [initialValues, setPersistedValues]
        )

        const methods = useForm<OrderFormValues>({
            defaultValues: getDefaultValues(persistedValues),
            shouldFocusError: true,
        })

        useEffect(() => {
            if (methods.formState.isSubmitSuccessful) {
                setPersistedValues(undefined)
                setRecommendedPrice(undefined)

                if (typeof onOrderCreateSuccess === 'function') {
                    onOrderCreateSuccess()
                }
            }

            return () => {
                setRecommendedPrice(undefined)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [methods.formState.isSubmitSuccessful])

        return (
            <FormProvider {...methods}>
                <div className={styles['order-form']}>
                    <section className={styles['order-form__content']}>
                        <header className={styles['order-form__header']}>
                            <Button
                                isText
                                style={{ color: 'var(--text-and-icon-accent)' }}
                                variant='ghost'
                                prefixEl={
                                    <IconArrowLeft color='var(--text-and-icon-accent)' size={20} />
                                }
                                onClick={navigateBack}
                            >
                                Назад
                            </Button>
                            <IconArrowUp color='var(--border-primary)' size={40} />
                            <OrderFormPersist addresses={addresses} />
                        </header>
                        <main>
                            <form
                                className={styles['order-form__fields']}
                                onSubmit={(event) => {
                                    analytics.track('b2b_create_order_send')
                                    event.preventDefault()
                                    event.stopPropagation()

                                    return methods
                                        .handleSubmit(onSubmit)()
                                        .catch((error: ErrorStruct) => {
                                            if (
                                                error.error_code &&
                                                ERROR_CODES_TO_GENERATING_NEW_IDEMPOTENCY_UUID.has(
                                                    error.error_code
                                                )
                                            ) {
                                                methods.setValue('id', uuidv7())
                                            }
                                        })
                                }}
                            >
                                <OrderFormSender addresses={addresses} />
                                <OrderFormPoints cityId={user.city.id} />
                                <OrderFormComment />
                                <OrderFormOptions />
                                <OrderFormPrice />
                                <div className={styles['order-form__controls--desktop']}>
                                    <OrderFormSubmitButton />
                                </div>
                                <Tapbar>
                                    <OrderFormSubmitButton />
                                </Tapbar>
                            </form>
                        </main>
                    </section>
                    <section className={styles['order-form__map']}>
                        <Suspense>
                            <Map center={user.city.location}>
                                <OrderFormMap />
                            </Map>
                        </Suspense>
                    </section>
                </div>
            </FormProvider>
        )
    }
)

OrderForm.displayName = 'OrderForm'
