import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconArrowUp: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                clipRule='evenodd'
                d='M12.3183 11.0723C12.1087 10.975 11.762 10.9764 11.5561 11.0735L11.1298 10.1689L11.5561 11.0735L3.42628 14.9046C2.92668 15.14 2.33083 14.9259 2.0954 14.4263C1.85997 13.9267 2.07412 13.3308 2.57371 13.0954L10.7035 9.26427C11.4474 8.91371 12.4149 8.91215 13.1604 9.25823L21.4211 13.093C21.922 13.3255 22.1396 13.9201 21.907 14.4211C21.6745 14.922 21.0799 15.1396 20.5789 14.907L12.3183 11.0723Z'
                fill={color}
                fillRule='evenodd'
            />
        </svg>
    )
}
