import React, { FC, forwardRef, ReactNode, useRef, useState } from 'react'
import { useOnClickOutside } from '@dostavkee/react-hooks'
import clsx from 'clsx'

import { Input, InputProps } from '../input'
import styles from './select.module.scss'

export interface SelectOption {
    id: string | number
    name: string
    hint?: string
}

export interface SelectProps extends Omit<InputProps, 'onChange' | 'value'> {
    options?: SelectOption[]
    value?: SelectOption
    footer?: (isOpen: boolean, setIsOpen: (isOpen: boolean) => void) => ReactNode
    id?: string
    onChange: (option: SelectOption) => void
}

export const Select: FC<SelectProps> = forwardRef<HTMLDivElement, SelectProps>(
    (
        { value, placeholder, options, onChange, isInvalid, errorMessage, label, footer, id },
        ref
    ) => {
        const [isOpen, setIsOpen] = useState(false)

        const contentRef = useRef<HTMLDivElement>(null)

        useOnClickOutside(contentRef, () => {
            if (isOpen) {
                setIsOpen(false)
            }
        })

        const handleSelect = (option: SelectOption) => {
            onChange(option)
            setIsOpen(false)
        }

        return (
            <div ref={ref} className={styles['select-wrapper']}>
                <div ref={contentRef}>
                    <Input
                        errorMessage={errorMessage}
                        id={id}
                        isInvalid={isInvalid}
                        label={label}
                        placeholder={placeholder}
                        type='button'
                        value={value?.name}
                        className={clsx(styles['select'], {
                            [styles['select-open']]: isOpen,
                        })}
                        postfixEl={
                            <svg
                                fill='none'
                                height='7'
                                viewBox='0 0 12 7'
                                width='12'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    clipRule='evenodd'
                                    d='M11.7364 0.263993C11.3849 -0.0874781 10.8151 -0.0874767 10.4636 0.263996L6.00002 4.7276L1.53639 0.263993C1.18492 -0.0874777 0.615073 -0.0874762 0.263602 0.263997C-0.087869 0.61547 -0.087868 1.18532 0.263605 1.53679L5.36363 6.63679C5.7151 6.98826 6.28495 6.98826 6.63642 6.63679L11.7364 1.53679C12.0879 1.18531 12.0879 0.615464 11.7364 0.263993Z'
                                    fill='#323942'
                                    fillRule='evenodd'
                                />
                            </svg>
                        }
                        onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            setIsOpen((state) => !state)
                        }}
                    />
                    {isOpen && (
                        <div className={styles['select-list']}>
                            {options?.map((option) => (
                                <div
                                    key={option.id}
                                    className={styles['select-list__select-option']}
                                    role='button'
                                    onClick={() => handleSelect(option)}
                                >
                                    {option.name}
                                    {option.hint && (
                                        <div className={styles['select-list__select-option-hint']}>
                                            {option.hint}
                                        </div>
                                    )}
                                </div>
                            ))}
                            {typeof footer === 'function' && footer(isOpen, setIsOpen)}
                        </div>
                    )}
                </div>
            </div>
        )
    }
)

Select.displayName = 'Select'
