const ruOrdinalRules = new Intl.PluralRules('ru')

export const formatPlural = (
    amount: number,
    suffixes: Partial<Record<'zero' | 'one' | 'two' | 'few' | 'many' | 'other', string>>
) => {
    const rule = ruOrdinalRules.select(amount)
    const suffix = suffixes[rule]

    if (suffix?.includes('%value%')) {
        return suffix.replace('%value%', String(amount))
    }

    return `${amount} ${suffix}`
}
