import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

import client from '../../axios-client'
import type {
    GetOrdersOrderIdLocation400,
    GetOrdersOrderIdLocationPathParams,
    GetOrdersOrderIdLocationQueryResponse,
} from '../entities/GetOrdersOrderIdLocation'

type GetOrdersOrderIdLocationClient = typeof client<
    GetOrdersOrderIdLocationQueryResponse,
    GetOrdersOrderIdLocation400,
    never
>
type GetOrdersOrderIdLocation = {
    data: GetOrdersOrderIdLocationQueryResponse
    error: GetOrdersOrderIdLocation400
    request: never
    pathParams: GetOrdersOrderIdLocationPathParams
    queryParams: never
    headerParams: never
    response: GetOrdersOrderIdLocationQueryResponse
    client: {
        parameters: Partial<Parameters<GetOrdersOrderIdLocationClient>[0]>
        return: Awaited<ReturnType<GetOrdersOrderIdLocationClient>>
    }
}
export const getOrdersOrderIdLocationQueryKey = (
    orderId: GetOrdersOrderIdLocationPathParams['order_id']
) => [{ url: '/orders/:order_id/location', params: { orderId: orderId } }] as const
export type GetOrdersOrderIdLocationQueryKey = ReturnType<typeof getOrdersOrderIdLocationQueryKey>
export function getOrdersOrderIdLocationQueryOptions(
    orderId: GetOrdersOrderIdLocationPathParams['order_id'],
    options: GetOrdersOrderIdLocation['client']['parameters'] = {}
) {
    const queryKey = getOrdersOrderIdLocationQueryKey(orderId)

    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<
                GetOrdersOrderIdLocation['data'],
                GetOrdersOrderIdLocation['error']
            >({
                method: 'get',
                url: `/orders/${orderId}/location`,
                ...options,
            })

            return res.data
        },
    })
}
/**
 * @description Получение локации курьера
 * @summary Получение локации курьера
 * @link /orders/:order_id/location
 */
export function useGetOrdersOrderIdLocation<
    TData = GetOrdersOrderIdLocation['response'],
    TQueryData = GetOrdersOrderIdLocation['response'],
    TQueryKey extends QueryKey = GetOrdersOrderIdLocationQueryKey,
>(
    orderId: GetOrdersOrderIdLocationPathParams['order_id'],
    options: {
        query?: Partial<
            QueryObserverOptions<
                GetOrdersOrderIdLocation['response'],
                GetOrdersOrderIdLocation['error'],
                TData,
                TQueryData,
                TQueryKey
            >
        >
        client?: GetOrdersOrderIdLocation['client']['parameters']
    } = {}
): UseQueryResult<TData, GetOrdersOrderIdLocation['error']> & {
    queryKey: TQueryKey
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {}
    const queryKey = queryOptions?.queryKey ?? getOrdersOrderIdLocationQueryKey(orderId)
    const query = useQuery({
        ...(getOrdersOrderIdLocationQueryOptions(
            orderId,
            clientOptions
        ) as unknown as QueryObserverOptions),
        queryKey,
        ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
    }) as UseQueryResult<TData, GetOrdersOrderIdLocation['error']> & {
        queryKey: TQueryKey
    }
    query.queryKey = queryKey as TQueryKey

    return query
}
