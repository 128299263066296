import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { AddressModel } from '@dostavkee/contracts-console/entities'
import { useDeviceDetection, usePrevious } from '@dostavkee/react-hooks'
import { Button, IconRepeat } from '@dostavkee/web-ui'
import isEqual from 'lodash.isequal'
import { useFormContext, useWatch } from 'react-hook-form'
import { uuidv7 } from 'uuidv7'

import { useFormPersist } from './hooks/use-form-persist'
import { useRecommendedPrice } from './hooks/use-recommended-price'
import {
    DEFAULT_VALUES_ORDER_FORM,
    DEFAULT_VALUES_ORDER_FORM_POINT,
    OrderFormValues,
} from './order-form'

interface OrderFormPersistProps {
    addresses?: AddressModel[]
}

export const OrderFormPersist: FC<OrderFormPersistProps> = () => {
    const { control, reset } = useFormContext<OrderFormValues>()
    const { setRecommendedPrice } = useRecommendedPrice()
    const { setPersistedOrderFormValues } = useFormPersist()
    const watchedValues = useWatch({ control })
    const sender = useWatch({ name: 'sender', control })
    const previousSender = usePrevious(sender)
    const { isMobile } = useDeviceDetection()

    useEffect(() => {
        if (Object.entries(watchedValues).length > 0) {
            // TODO: Fix this
            // @ts-ignore
            setPersistedOrderFormValues({
                ...watchedValues,
                _timestamp: Date.now(),
            })
        }
    }, [setPersistedOrderFormValues, watchedValues])

    const handleClearStorage = useCallback(() => {
        reset(
            {
                ...DEFAULT_VALUES_ORDER_FORM,
                comment: sender?.comment ?? '',
                id: uuidv7(),
            },
            {
                keepDefaultValues: true,
            }
        )
        setPersistedOrderFormValues(undefined)
        setRecommendedPrice(undefined)
    }, [reset, sender?.comment, setPersistedOrderFormValues, setRecommendedPrice])

    const isChanged = useMemo(() => {
        if (previousSender?.id && sender?.id && previousSender?.id !== sender?.id) {
            return false
        }

        const [firstPoint, ...anotherPoints] = watchedValues.points ?? []
        const { address_name: _, ...firstPointWithoutAddressName } = firstPoint ?? {}

        const isChanged = Boolean(
            !isEqual(watchedValues.options, DEFAULT_VALUES_ORDER_FORM.options) ||
                !isEqual(firstPointWithoutAddressName, sender) ||
                (watchedValues?.comment ?? '') !== (sender?.comment ?? '') ||
                anotherPoints.some((point) => !isEqual(point, DEFAULT_VALUES_ORDER_FORM_POINT)) ||
                watchedValues.price ||
                watchedValues?.what_take
        )

        return isChanged
    }, [
        previousSender?.id,
        sender,
        watchedValues.points,
        watchedValues.options,
        watchedValues?.comment,
        watchedValues.price,
        watchedValues?.what_take,
    ])

    if (isChanged)
        return (
            <Button
                isText
                size={isMobile ? 'small' : 'medium'}
                variant='ghost'
                prefixEl={
                    isMobile ? undefined : (
                        <IconRepeat color='var(--extensions-text-and-icon-error)' size={20} />
                    )
                }
                style={{
                    color: 'var(--extensions-text-and-icon-error)',
                }}
                onClick={handleClearStorage}
            >
                Сбросить
            </Button>
        )

    return null
}
