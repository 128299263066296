import { useRef } from 'react'
import { getGeoSearch } from '@dostavkee/contracts-console/api'
import { GeoAddress, GeoAdmDiv } from '@dostavkee/contracts-console/entities'
import { AutocompleteOption } from '@dostavkee/web-ui'

interface UseAddressesProps {
    cityId: string
}

const getSuggestHint = (adm?: GeoAdmDiv[]) => {
    if (!adm) {
        return
    }

    const city = adm.find((item) => item.type === 'city')
    const living_area = adm.find((item) => item.type === 'living_area')

    if (living_area && city) {
        return `г. ${city.name}, ${living_area.name}`
    }

    if (living_area) {
        return `${living_area.name}`
    }

    const settlement = adm.find((item) => item.type === 'settlement')
    if (settlement) {
        return `${settlement.name}`
    }

    if (city) {
        return `г. ${city.name}`
    }

    const region = adm.find((item) => item.type === 'region')
    if (region) {
        return region.name
    }

    const country = adm.find((item) => item.type === 'country')
    if (country) {
        return country.name
    }
}

export const useAddresses = (props: UseAddressesProps) => {
    const suggestedAddresses = useRef<GeoAddress[] | null>(null)

    const loadAddressesOptions = async (
        inputValue: string
    ): Promise<AutocompleteOption<GeoAddress>[]> => {
        const result = await getGeoSearch({ city_id: props.cityId, q: inputValue })

        if (!result?.addresses) {
            return []
        }

        suggestedAddresses.current = result.addresses

        const options: AutocompleteOption<GeoAddress>[] = result.addresses.map((item) => ({
            id: item.id!,
            name: item.address!,
            hint: getSuggestHint(item.adm_div),
        }))

        return options
    }

    return {
        loadAddressesOptions,
        suggestedAddresses,
    }
}
