import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconBolt: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10.6831 20.6855C10.5061 20.8856 10.2528 21 9.98674 21C9.38784 21 8.94418 20.4396 9.07756 19.8516L10.3494 14.2445C10.3778 14.1193 10.2834 14 10.1558 14H5.92906C5.16163 14 4.72631 13.1148 5.19141 12.5C7.19288 10.2722 9.38135 7.66244 13.3171 3.30875C13.4944 3.11268 13.7465 3 14.0098 3C14.6112 3 15.0567 3.56276 14.9228 4.15323L13.652 9.75546C13.6236 9.88067 13.7181 10 13.8456 10H18.0709C18.8384 10 19.2737 10.8852 18.8086 11.5L10.6831 20.6855Z'
                fill={color}
            />
        </svg>
    )
}
