/* eslint-disable unicorn/no-useless-undefined */
import { GetRecPriceResponse } from '@dostavkee/contracts-console/entities'
import { atom, useAtom, useAtomValue } from 'jotai'

import { useAuth } from '@/shared/hooks'

/**
 * Enum representing the different modes for recommended price.
 */
export enum RecommendedPriceMode {
    DYNAMIC_PRICE_WITH_BONUS = 'DYNAMIC_PRICE_WITH_BONUS',
    DYNAMIC_PRICE = 'DYNAMIC_PRICE',
    STATIC_PRICE_WITH_BONUS = 'STATIC_PRICE_WITH_BONUS',
    STATIC_PRICE = 'STATIC_PRICE',
    NONE = 'NONE',
}

/**
 * Atom representing the recommended price response.
 */
const recommendedPriceAtom = atom<GetRecPriceResponse | undefined>(undefined)

/**
 * Atom representing the mode of the recommended price.
 * The mode is determined based on the recommended price response.
 */
const modeAtom = atom((get) => {
    const recommendedPrice = get(recommendedPriceAtom)
    if (recommendedPrice?.dynamic_recprice) {
        if (
            recommendedPrice?.dynamic_recprice?.min_with_bonus &&
            recommendedPrice?.dynamic_recprice?.max_with_bonus
        ) {
            return RecommendedPriceMode.DYNAMIC_PRICE_WITH_BONUS
        }

        if (recommendedPrice?.dynamic_recprice?.min && recommendedPrice?.dynamic_recprice?.max) {
            return RecommendedPriceMode.DYNAMIC_PRICE
        }
    }

    if (recommendedPrice?.rec_price_with_bonus) {
        return RecommendedPriceMode.STATIC_PRICE_WITH_BONUS
    }

    if (recommendedPrice?.rec_price) {
        return RecommendedPriceMode.STATIC_PRICE
    }

    return RecommendedPriceMode.NONE
})

/**
 * Atom representing the minimum price.
 * The minimum price is obtained from the recommended price response.
 */
const minPriceAtom = atom((get) => {
    const recommendedPrice = get(recommendedPriceAtom)

    if (recommendedPrice?.min_price) {
        return recommendedPrice.min_price
    }

    return 0
})

/**
 * Atom representing the price.
 * The price is determined based on the mode of the recommended price.
 */
const priceTextAtom = atom((get) => {
    const recommendedPrice = get(recommendedPriceAtom)
    const mode = get(modeAtom)

    switch (mode) {
        case RecommendedPriceMode.DYNAMIC_PRICE_WITH_BONUS: {
            if (
                recommendedPrice?.dynamic_recprice?.min_with_bonus ===
                recommendedPrice?.dynamic_recprice?.max_with_bonus
            ) {
                return `${recommendedPrice?.dynamic_recprice?.min_with_bonus} ₽`
            }

            return `${recommendedPrice?.dynamic_recprice?.min_with_bonus}-${recommendedPrice?.dynamic_recprice?.max_with_bonus} ₽`
        }
        case RecommendedPriceMode.DYNAMIC_PRICE: {
            if (
                recommendedPrice?.dynamic_recprice?.min === recommendedPrice?.dynamic_recprice?.max
            ) {
                return `${recommendedPrice?.dynamic_recprice?.min} ₽`
            }

            return `${recommendedPrice?.dynamic_recprice?.min}-${recommendedPrice?.dynamic_recprice?.max} ₽`
        }
        case RecommendedPriceMode.STATIC_PRICE_WITH_BONUS: {
            return `${recommendedPrice?.rec_price_with_bonus} ₽`
        }
        case RecommendedPriceMode.STATIC_PRICE: {
            return `${recommendedPrice?.rec_price} ₽`
        }
        default: {
            return undefined
        }
    }
})

/**
 * Atom representing the recommended price.
 * The recommended price is determined based on the mode of the recommended price.
 */
const throughPriceTextAtom = atom((get) => {
    const recommendedPrice = get(recommendedPriceAtom)
    const mode = get(modeAtom)
    switch (mode) {
        case RecommendedPriceMode.DYNAMIC_PRICE_WITH_BONUS:
        case RecommendedPriceMode.DYNAMIC_PRICE: {
            if (
                recommendedPrice?.dynamic_recprice?.min === recommendedPrice?.dynamic_recprice?.max
            ) {
                return `${recommendedPrice?.dynamic_recprice?.min} ₽`
            }

            return `${recommendedPrice?.dynamic_recprice?.min}-${recommendedPrice?.dynamic_recprice?.max} ₽`
        }
        case RecommendedPriceMode.STATIC_PRICE_WITH_BONUS:
        case RecommendedPriceMode.STATIC_PRICE: {
            return `${recommendedPrice?.rec_price} ₽`
        }
        default: {
            return undefined
        }
    }
})

/**
 * Custom hook for using the recommended price.
 * It provides the mode, throughPrice, price, minPrice, recommendedPrice, setRecommendedPrice, and isHighRate values.
 */
export const useRecommendedPrice = () => {
    /**
     * Get city options from useAuth hook
     */
    const { cityOptions } = useAuth()

    /**
     * Get and set recommended price using atom
     */
    const [recommendedPrice, setRecommendedPrice] = useAtom<GetRecPriceResponse | undefined>(
        recommendedPriceAtom
    )

    /**
     * Get mode value from atom
     */
    const mode = useAtomValue(modeAtom)

    /**
     * Get minPrice value from atom
     */
    const minPrice = useAtomValue(minPriceAtom)

    /**
     * Get price value from atom
     */
    const priceText = useAtomValue(priceTextAtom)

    /**
     * Get throughPrice value from atom
     */
    const throughPriceText = useAtomValue(throughPriceTextAtom)

    return {
        mode,
        throughPriceText,
        priceText,
        minPrice: minPrice ?? cityOptions?.recprice?.recprice_minprice ?? 0,
        recommendedPrice,
        setRecommendedPrice,
        isHighRate: Boolean(recommendedPrice?.dynamic_recprice?.high_rate),
    }
}
