import type { ResponseConfig } from '../../axios-client'
import client from '../../axios-client'
import type { GetGeoSearchQueryParams, GetGeoSearchQueryResponse } from '../entities/GetGeoSearch'

/**
 * @description Автокомплит адресов
 * @summary Автокомплит адресов
 * @link /geo/search
 */
export async function getGeoSearch(
    params: GetGeoSearchQueryParams,
    options: Partial<Parameters<typeof client>[0]> = {}
): Promise<ResponseConfig<GetGeoSearchQueryResponse>['data']> {
    const res = await client<GetGeoSearchQueryResponse>({
        method: 'get',
        url: `/geo/search`,
        params,
        ...options,
    })

    return res.data
}
