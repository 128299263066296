import React, { useEffect, useMemo, useRef } from 'react'
import { LocationModel, ServiceOrder } from '@dostavkee/contracts-console/entities'
import { useGetOrdersOrderIdLocation } from '@dostavkee/contracts-console/hooks'
import { GetOrderPublicInfoResponse } from '@dostavkee/contracts-external/entities'
import Leaflet from 'leaflet'
import { Marker } from 'react-leaflet'

import svgIconCourierWalk from '@/assets/map-bike-courier-color-dark.svg'
import svgIconCourierAuto from '@/assets/map-car-color-dark.svg'
import { REFRESH_INTERVAL_LOCATION, STAGES_ALLOWED_POLLING_LOCATION } from '@/shared/constants'

const COURIER_ICON = {
    walking: svgIconCourierWalk,
    auto: svgIconCourierAuto,
}

const COURIER_BRAND_VALUES = new Set(Object.keys(COURIER_ICON))

export interface OrderCourierLocationMarkerProps {
    order: ServiceOrder | GetOrderPublicInfoResponse
}

export const OrderCourierLocationMarker: React.FC<OrderCourierLocationMarkerProps> = ({
    order,
}) => {
    // Fetch the location data for the order
    const { data } = useGetOrdersOrderIdLocation(order.id, {
        query: {
            refetchInterval: REFRESH_INTERVAL_LOCATION,
            enabled: order?.stage && STAGES_ALLOWED_POLLING_LOCATION.has(order.stage),
        },
    })

    const currentLocation = data?.location

    const previousLocationRef = useRef<LocationModel>()

    useEffect(() => {
        // Store the current location as the previous location
        // if the current location is available
        if (currentLocation?.lat) {
            previousLocationRef.current = currentLocation
        }
    }, [currentLocation])

    const iconCourier = useMemo(() => {
        const type = order.courier?.type
        let icon: keyof typeof COURIER_ICON = 'walking'

        // Determine the courier icon based on the courier type
        if (type && COURIER_BRAND_VALUES.has(type.toLowerCase())) {
            icon = type.toLowerCase() as keyof typeof COURIER_ICON
        }

        return new Leaflet.Icon({
            iconUrl: COURIER_ICON[icon],
            iconRetinaUrl: COURIER_ICON[icon],
            iconSize: new Leaflet.Point(32, 32),
        })
    }, [order.courier?.type])

    if (currentLocation?.lat) {
        // Render the marker with the current location
        return <Marker icon={iconCourier} position={[currentLocation.lat, currentLocation.lng]} />
    }

    if (previousLocationRef.current?.lat) {
        // Render the marker with the previous location
        return (
            <Marker
                icon={iconCourier}
                position={[previousLocationRef.current.lat, previousLocationRef.current.lng]}
            />
        )
    }

    // If no location data is available, return null
    return null
}
