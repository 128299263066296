import React, { FC, useMemo, useRef } from 'react'
import { V1CategoryModel } from '@dostavkee/contracts-console/entities'
import { Button } from '@dostavkee/web-ui'

import { useAuth } from '@/shared/hooks'

import { categories as categoryVariants } from './fixtures/category-variants.json'
import styles from './order-form-comment-variants.module.scss'

const getRelevantVariants = (category?: V1CategoryModel): string[] => {
    if (category && !category.name) {
        return categoryVariants.find((variants) => variants.name === 'unknown')!.relevant_variants
    }

    const expectCategory = categoryVariants.find(
        (variants) => category?.name?.toLowerCase() === variants.name.toLowerCase()
    )

    if (expectCategory) {
        return expectCategory.relevant_variants
    }

    return categoryVariants.find((variants) => variants.name === 'unknown')!.relevant_variants
}

interface OrderFormCommentVariantsProps {
    onChange: (comment: string) => void
}

export const OrderFormCommentVariants: FC<OrderFormCommentVariantsProps> = ({ onChange }) => {
    const { company } = useAuth()

    const ref = useRef<HTMLDivElement>(null)

    const relevantVariants = useMemo(
        () => getRelevantVariants(company?.category),
        [company?.category]
    )

    return (
        <div className={styles['comment-variants-container']}>
            <div ref={ref} className={styles['comment-variants']}>
                {relevantVariants.map((comment) => (
                    <Button
                        key={comment}
                        isRounded
                        size='xs'
                        variant='secondary'
                        onClick={(event) => {
                            ref.current?.scrollTo({
                                left: event.currentTarget.offsetLeft,
                                behavior: 'smooth',
                            })
                            onChange(comment)
                        }}
                    >
                        {comment}
                    </Button>
                ))}
            </div>
        </div>
    )
}
